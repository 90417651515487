//文本超出1行省略
.ellipsis-row {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

//文本超出2行省略
.ellipsis-row-2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.apply-title {
  font-size: 16px;
    font-weight: 500;
    position: relative;
    margin-left: 5px;
    &:before {
      content: '';
      width: 7px;
      height: 26px;
      background: #2E8CEA;
      position: absolute;
      left: -11px;
    }
}
.fx-flexbox {
  display: flex;
  align-items: center;
}