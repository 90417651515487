.flex-box {
  display: flex;
}

.goods-detail-page {
  .goods-detail-title {
    position: relative;
    height: 56px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #4a4a4a;
    line-height: 56px;
    text-indent: 12px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 22px;
      width: 4px;
      height: 12px;
      background: #2e8cea;
      border-radius: 3px;
    }
  }

  .line {
    height: 1px;
    background: #e8e8e8;
  }

  .goods-main-info {
    display: flex;
    align-items: center;
    height: 54px;
    margin-bottom: 8px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4a4a4a;

    & > div {
      width: 220px;
      margin-right: 60px;
    }
  }

  .info-box {
    flex-grow: 1;
    margin-left: 40px;
    overflow: hidden;

    .goods-name {
      min-height: 27px;
      max-height: 54px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #444444;
      line-height: 27px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      word-break: break-all;
      -webkit-box-orient: vertical;
    }

    .goods-desc {
      height: 27px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 27px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .goods-price-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 132px;
      margin-top: 10px;
      margin-bottom: 15px;
      padding-left: 10px;
      background-color: #f9f9f9;

      .price-item {
        display: flex;

        .price-label {
          width: 100px;
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #777777;
          line-height: 22px;
          letter-spacing: 10px;

          &.sale {
            height: 30px;
            line-height: 30px;
          }
        }

        .price-value {
          width: 150px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #777777;

          &.spec {
            width: 400px;
          }

          &.sale {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #d1302c;
          }
        }
      }
    }

    .goods-spec-box {
      display: flex;
      margin-bottom: 24px;

      .goods-spec-label {
        width: 100px;
        height: 22px;
        padding-left: 10px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #777777;
        line-height: 22px;
        letter-spacing: 5px;
      }

      .goods-spec-value {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;

        .express-cost {
          position: relative;
          margin-left: 26px;
          padding-left: 10px;
          color: #666666;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 3px;
            width: 2px;
            height: 14px;
            background-color: #aaa;
          }
        }

        .spec-item {
          margin-bottom: 20px;
          padding-bottom: 10px;
          border-bottom: 1px solid #ccc;

          > div {
            width: 120px;
            margin-right: 20px;
          }
        }

        .ant-radio-button-wrapper {
          margin-right: 10px;
          border-radius: 0;
          height: auto;
        }

        .ant-radio-button-wrapper:hover {
          color: #db211d;
        }

        .ant-radio-button-wrapper-checked,
        .ant-radio-button-wrapper-checked:hover {
          color: #db211d;
          border: 1px solid #db211d;
          box-shadow: none;
        }
      }
    }
  }

  .goods-info {
    background: #ffffff;
    border: 1px solid #e5e3e3;
    margin: 42px 0 40px;

    .goods-info-title {
      display: flex;
      align-items: center;
      height: 50px;
      padding-left: 20px;
      background: #f4f4f4;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;

      &-item {
        margin-right: 79px;
        padding-bottom: 3px;
        border-bottom: 2px solid transparent;
        cursor: pointer;

        &-actived,
        &:hover {
          font-weight: 600;
          color: #d1302c;
          border-bottom: 2px solid #d1302c;
        }
      }
    }

    .goods-detail {
      margin-bottom: 60px;
      padding-top: 20px;
      justify-content: center;
    }

    .goods-info-box {
      display: flex;
      flex-wrap: wrap;

      > p {
        width: 20%;
        line-height: 2em;
      }

      .goods-params-wrap {
        display: flex;
        padding: 12px 0;
        color: #999999;
        border-bottom: 1px solid #e5e3e3;

        &:last-child {
          border-bottom: none;
        }
      }

      .goods-params-title {
        flex-shrink: 0;
        width: 110px;
        margin-right: 20px;
        text-align: right;
        line-height: 28px;
      }

      .goods-params-item {
        display: flex;
        line-height: 28px;

        .goods-params-label {
          flex-shrink: 0;
          width: 160px;
          margin-right: 45px;
          padding-right: 5px;
          text-align: right;
        }

        .goods-params-value {
          padding-right: 20px;
        }
      }
    }
  }
}
