/*修改滚动条样式*/
html::-webkit-scrollbar,
body::-webkit-scrollbar,
div::-webkit-scrollbar,
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

html::-webkit-scrollbar-track,
body::-webkit-scrollbar-track,
div::-webkit-scrollbar-track,
*::-webkit-scrollbar-track, {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb, {
  background: #e0e0e0;
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb:hover,
body::-webkit-scrollbar-thumb:hover,
div::-webkit-scrollbar-thumb:hover,
*::-webkit-scrollbar-thumb:hover, {
  background: #999;
}

html::-webkit-scrollbar-corner,
body::-webkit-scrollbar-corner,
div::-webkit-scrollbar-corner,
*::-webkit-scrollbar-corner, {
  background: #179a16;
}
